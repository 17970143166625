// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-art-piece-js": () => import("/opt/build/repo/src/templates/art-piece.js" /* webpackChunkName: "component---src-templates-art-piece-js" */),
  "component---src-templates-category-js": () => import("/opt/build/repo/src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

